<template>
    <v-dialog
        :max-width="'600px !important'"
        persistent
        v-model="addToDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
    >
        <v-card>
            <v-card-title class="warning white--text justify-space-between">
                <span>{{ $t('message.addToLhdnInvoice') + ': ' + invoiceNumber }}</span>
            </v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="addToForm" v-model="validForm">
                    <v-layout row pt-3>
                        <p class="black--text pl-5 mt-3 pb-0">
                            {{ 'A debit note is required to increase the amount of a previously issued '
                                + 'e-invoice which is already more than 72 hours old '
                                + 'since the date and time it was validated. '
                                + 'To decrease the amount please issue a credit note.'
                            }}
                        </p>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.referenceInvoiceNumber') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1 pl-3 class="black--text text-darken-1">{{ invoiceNumber }}</v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.debitNoteNumber') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :loading="loading.debitNoteNumber"
                                :value="debit_note_number"
                                :label="debit_note_number ? '' : $t('message.required')"
                                :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                autocomplete="new-password"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                required
                                @change="debit_note_number = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.amountToAdd') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :value="amountToAdd"
                                :label="amountToAdd ? '' : $t('message.required')"
                                :rules="[...validationRules.required, ...validationRules.number, ...validationRules.minimumOne]"
                                autocomplete="new-password"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                type="number"
                                solo
                                required
                                @change="amountToAdd = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.reasonForAddition') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-lazy>
                                <v-textarea
                                    :value="reasonToAdd"
                                    :placeholder="$t('message.selectOptionOrFreeText')"
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                    autocomplete="new-password"
                                    auto-grow
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    rows="1"
                                    solo
                                    required
                                    @change="reasonToAdd = $event"
                                />
                            </v-lazy>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="addToDialog = false" >{{ $t('message.dismiss') }}</v-btn>
                <v-btn
                    :loading="loading.proceed"
                    color="info"
                    small
                    @click="addToDocument()"
                >{{ $t('message.proceed') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {api} from "Api";
import {mapGetters} from "vuex";
import {numberFormat} from "Helpers/helpers";
import _ from 'lodash';

export default {
    name: "AddChargesToDocument",
    props: ['dialog', 'invoiceNumber','jobId','documentUuid'],
    data() {
        return {
            addTo_dialog: false,
            debit_note_number: null,
            loading: {
                debitNoteNumber: false,
                items: false,
                proceed: false
            },
            amountToAdd: 0,
            newAmount: 0,
            originalAmount: 0,
            reasonToAdd: null,
            validForm: true
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        addToDialog: {
            get() {
                return this.addTo_dialog;
            },
            set(value){
                this.addTo_dialog = value
                if(value === false) {
                    this.$emit('dialog-closed')
                }
            }
        },
        totalAmountValid() {
            return this.newAmount >= this.originalAmount;
        }
    },
    methods: {
        async addToDocument() {
            if(this.totalAmountValid) {
                if (this.$refs.addToForm.validate()) {
                    if (await this.$root.$confirm(this.$t('message.addToLhdnInvoice') + ' ' + this.invoiceNumber, this.$t('message.confirmations.continueLhdnAddToAction'), {color: 'orange'})) {
                        this.loading.proceed = true;
                        api
                            .put('/lhdn/document/add-charges/' + this.jobId, {
                                debit_note_number: this.debit_note_number,
                                amount: this.amountToAdd,
                                reason: this.reasonToAdd
                            })
                            .then((response) => {
                                if (response.data.status === 'success') {
                                    this.$toast.success(this.$t('message.successes.lhdnAddedTo'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                    this.loading.proceed = false;
                                    this.$emit('add-to-done')
                                    this.addToDialog = false;
                                } else {
                                    this.$toast.error(this.$t('message.errors.lhdnNotAddedTo'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    this.loading.proceed = false;
                                }
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('message.errors.lhdnNotAddedTo'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.proceed = false;
                            })
                    }
                } else {
                    this.$toast.error(this.$t('message.errors.lhdnNotAddedTo'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            } else {
                this.$toast.error(this.$t('message.errors.totalAmountLessThanOriginalAmount'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        formatThisNumber(value, format) {
            return numberFormat(value, format);
        },
        getNextDebitNoteNumber() {
            this.loading.debitNoteNumber = true
            api
                .get('/lhdn/next-document-no/dn')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.debit_note_number = response.data.data
                    } else {
                        this.$toast.error(this.$t('message.errors.cannotGetNextDebitNoteNumber'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading.debitNoteNumber = false
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.cannotGetNextDebitNoteNumber'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.debitNoteNumber = false
                })
        }
    },
    watch: {
        dialog(value) {
            if(value) {
                this.debit_note_number = null;
                this.reasonToAdd = null;
                this.amountToAdd = 0;
                this.getNextDebitNoteNumber();
            } else {
                this.items = [];
            }
            this.addTo_dialog = value;
        }
    },
}
</script>

<style>
.bg-lightgrey {
    background-color: #F5F5F5;
}
.v-data-table td {
    padding: 0 3px !important;
    font-size: unset !important;
    line-height: unset !important;
}
table td.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
</style>